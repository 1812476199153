// Override default variables before the import
@import "variables.scss";

// Bootstrap
@import "bootstrap/scss/bootstrap.scss";

// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/v4-shims.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

html,
body,
#root {
  height: 100%;
  width: 100%;
  background: $body-color;
}

.container,
.container-fluid {
  .top-header {
    .btn-link {
      text-transform: unset;
    }
  }
}

.container-fluid {
  padding-right: 3%;
  padding-left: 3%;
}

.sumeet-header {
  z-index: 100;

  .navbar {
    background-color: transparent !important;
    padding: 0.2rem 1rem;

    @include media-breakpoint-down(sm) {
      .navbar-brand {
        display: none;
      }
    }
  }
}

// Common elements
.btn {
  text-transform: uppercase;
}

.spinner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 50px;
}

.modal {
  .spinner {
    padding-top: 0px;
  }

  .modal-footer {
    button {
      &.btn-primary {
        @extend .mr-auto;
      }
    }
  }
}

.pagination {
  margin: 0;
}

.page-title {
  display: flex;
  align-items: baseline;
  .text {
    margin-right: 7px;
    color: $primary;
  }
  .link {
    font-size: 1rem;
  }
}

.custom-control {
  line-height: 1.8rem;
}

.btn {
  border-radius: 0;

  &.btn-light {
    border: 1px solid lighten($input-border-color, 30);
  }
}

a {
  &.active {
    color: $body-color;

    &:hover {
      color: lighten($body-color, 25);
    }
  }
}

.video-bg-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  .video-wrapper {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;

    .video-wrapper-container {
      position: absolute;
      z-index: -100;

      video {
        position: fixed;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}

.lobby-room {
  .jumbotron {
    position: relative;
    background-color: transparent;
    color: white;

    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }

    .title,
    .lead {
      text-align: center;
      color: $body-bg;

      .meet-url {
        white-space: pre-line;
        word-break: break-word;
      }
    }
  }

  .actions {
    form {
      @include media-breakpoint-up(md) {
        max-width: 540px;
        margin: auto;
      }

      .invalid-feedback {
        color: $body-bg;
      }

      .display-name,
      .room-id {
        font-size: 1.5rem;
      }
    }
  }
}

.sumeet-room {
  .close-room {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
}

.container-remote-video {
  position: relative;

  .guest-status {
    position: absolute;
    bottom: 0;
  }
}

.video-options {
  position: fixed;
  right: 0;
  z-index: 100;
  bottom: 0;

  button {
    opacity: 0.75;

    @include media-breakpoint-down(md) {
      padding: 3px 12px;
    }
  }
}

.video-options-notifications {
  position: fixed;
  right: 0;
  z-index: 100;
  bottom: 30px;
  display: none;
  color: #fff;

  &.enabled {
    display: block;
  }
}

.progress-bar-notifications {
  position: absolute;
  left: 10px;
  z-index: 100;
  bottom: 10px;
  // display: none;
  color: #fff;

  // &.enabled {
  //   display: block;
  // }
}

.videos {
  padding: 40px 40px 0 40px;

  @include media-breakpoint-up(xl) {
    padding: 5% 15% 0 15%;
  }

  .video-container {
    position: relative;
    overflow: hidden;

    .video-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      max-height: 100%;
      border: 2px solid black;

      video {
        position: relative;
        height: 100%;
        width: 100%;
        display: block;
      }
    }

    .video-label {
      color: white;
      font: $font-family-base;
      font-weight: bold;
      font-size: $h4-font-size;
      line-height: $line-height-base;
      height: 35px;

      background: rgba(0, 0, 0, 0.55);

      position: absolute;
      left: 2px;
      top: 2px;
      padding: 4px;
    }
  }

  &.grid {
    padding: 0;
    display: grid;
    grid-gap: 3px;
    grid-auto-flow: row;

    .video-container {
      .loading-container {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -1.5rem 0 0 -1.5rem;
      }
    }

    .talking {
      .video-container {
        .video-wrapper {
          border: 2px solid red;
        }
      }
    }

    &.guest-head-to-head {
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(auto-fit, minmax($colWidth/2, 1fr));

        .video-container {
          height: $rowHeight * 0.8;
          min-width: $colWidth/2;
        }
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(auto-fit, minmax($colWidth, 1fr));

        .video-container {
          height: $rowHeight/2;
          min-width: $colWidth;
        }
      }

      .video-container {
        opacity: 1;
        transition: opacity 1000ms ease-in;
      }
    }

    &.guest-no-alone {
      grid-template-columns: repeat(auto-fit, minmax($colWidth/2, 1fr));

      .video-container {
        height: $rowHeight/2;
        min-width: $colWidth/2;
      }
    }

    &.guest-group {
      grid-template-columns: repeat(auto-fit, minmax($colWidth/3, 1fr));

      .video-container {
        height: $rowHeight/3;
        min-width: $colWidth/3;
        opacity: 1;
        transition: opacity 1000ms ease-in;
      }
    }
  }

  .guest-enter {
    opacity: 0;
  }
  .guest-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .guest-exit {
    opacity: 1;
  }
  .guest-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: $body-bg;
  padding: 10px 0;

  @include media-breakpoint-down(xs) {
    padding: 0;

    .copyright-mobile {
      font-size: $font-size-base * 0.7;
    }
  }

  .copyright {
    position: relative;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .copyright-mobile {
    position: relative;
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}
