// Color system

$primary: #449bd6 !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 170 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: true !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

// Body
//
// Settings for the `<body>` element.
$body-bg: #ffffff !default;
$body-color: #2c2c32 !default;

// Links
//
// Style anchor elements.
$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 0 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1405px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1405px,
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-row-columns: 6 !default;

//
// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Nunito Sans", -apple-system, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1 !default;
$h6-font-size: $font-size-base * 0.9 !default;

$headings-color: #373737;
$headings-font-weight: 300 !default;

// Navbar
$navbar-light-color: $primary !default;
$navbar-light-hover-color: $primary !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: $primary !default;

// Buttons
$input-btn-focus-box-shadow: none !default;
$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: none !default;

// Forms
$input-padding-y: 0.5rem !default;
$input-padding-x: 0.5rem !default;
$input-color: #666666 !default;
$input-border-color: #666666 !default;
$input-placeholder-color: #464646 !default;
$input-border-radius: 2px !default;

$input-focus-box-shadow: none !default;

$custom-control-indicator-size: 1.5rem !default;
$custom-control-indicator-bg: white;
$custom-control-indicator-color: $primary;
$custom-control-indicator-border-color: $primary;
$custom-control-indicator-checked-bg: white;
$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-checked-border-color: $primary;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='-4 -4 8 8'><circle r='4' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

$rowHeight: 88vh;
$colWidth: 90vw;
